export default {
  title: "Pallets",
  loaded_at: "Loaded at",
  pallet_type: "Pallet Type",
  title_placeholder_empty: "No pallets on this Linehaul yet",
  subtitle_placeholder_empty: "Add pallets from the button below",
  remove_pallet: "Remove pallet",
  label_packages: "Packages",
  deleting: "Removing in process...",
  button_options: "Options",
  label_quantity: "How many pallets would you like to add?",
  add_pallets: "Add Pallets",
  pallet_type_header:"Pallets",
  pallet_type_title:"Edit pallet type",
  pallet_type_label:"Pallet Type",
};
